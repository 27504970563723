<template>
  <div class="library">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="产品编号">
          <el-input
            v-model="searchForm.productNo"
            clearable
            placeholder="请输入产品编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品类型">
          <el-select ref="searchFormProductType" v-model="searchForm.productType" clearable>
            <el-option
              v-for="opt in PRODUCT_TYPE"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="searchForm.time"
            clearable
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="goAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column
          prop="productNo"
          fixed
          min-width="200"
          label="产品编号"
        >
        </el-table-column>
        <el-table-column
          prop="productModel"
          fixed
          min-width="150"
          label="产品型号"
        >
        </el-table-column>
        <el-table-column prop="productType" label="产品类型" width="130">
          <template slot-scope="{ row }">
            {{ $changeObj(PRODUCT_TYPE)[row.productType] }}
          </template>
        </el-table-column>
        <el-table-column prop="electricVoltage" label="电池电压">
        </el-table-column>
        <el-table-column prop="batteryCapacity" label="电池容量">
        </el-table-column>
        <el-table-column
          prop="bmsSupplierName"
          label="BMS供应商名称"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="gpsSupplierName"
          label="GPS供应商名称"
          min-width="150"
        >
        </el-table-column>
        <el-table-column prop="bmsBoardType" label="BMS板">
          <template slot-scope="{ row }">
            {{ $changeObj(BMS_BOARD_TYPE)[row.bmsBoardType] }}
          </template>
        </el-table-column>
        <el-table-column prop="vehicleBrand" label="车辆品牌">
        </el-table-column>
        <el-table-column
          prop="vehicleSpecification"
          label="车辆规格"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180">
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="goEdit(row)">编辑</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { PRODUCT_TYPE, BMS_BOARD_TYPE, BATTERY_MATERIAL } from "@/js/constant";
import { getProductList, delProduct } from "@/api/product";

export default {
  data() {
    return {
      searchForm: {
        productNo: "",
        productType: "",
        time: ""
      },
      PRODUCT_TYPE,
      BMS_BOARD_TYPE,
      beginIndex: 1,
      pageSize: 20,
      startTime: "",
      endTime: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      list: [],
      total: 0
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.beginIndex = 1;
        _this.$refs.searchFormProductType.blur()
        _this.getProductList();
      }
    };
    this.getProductList();
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/addProduct') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    this.getProductList();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.getProductList();
    },
    async getProductList() {
      if (this.searchForm.time) {
        this.startTime = this.searchForm.time[0];
        this.endTime = this.searchForm.time[1];
      } else {
        this.startTime = null;
        this.endTime = null;
      }
      //改beginIndex为0
      let page = this.beginIndex;
      page = page - 1;

      //
      const params = {
        ...this.searchForm,
        startTime: String(Date.parse(this.startTime)),
        endTime: String(Date.parse(this.endTime)),
        beginIndex: page, //this.begindex改成page
        pageSize: this.pageSize
      };
      if (!this.endTime || !this.startTime) {
        params.startTime = null;
        params.endTime = null;
      }
      delete params.time;
      const res = await getProductList(params);
      //!!!
      this.list = res.ldata;
      this.total = res.mdata.total || 0;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getProductList();
    },
    /**删除 */
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            productNo: row.productNo
          };
          await delProduct(params);
          this.$message.success("删除成功");
          this.beginIndex = 1;
          this.getProductList();
        })
        .catch(() => {});
    },
    goAdd() {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/lease/addOrUpdateProduct' ).then((flag) => {
        if(flag) {
          this.$router.push("/leaseholdMgmt/addProduct");
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push("/leaseholdMgmt/addProduct");
      })

    },
    goEdit(row) {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/lease/addOrUpdateProduct' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addProduct",
            query: {
              productNo: row.productNo,
              type: "edit"
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path: "/leaseholdMgmt/addProduct",
          query: {
            productNo: row.productNo,
            type: "edit"
          }
        });
      })

    }
  },
  components: {}
};
</script>

<style scoped>
.library {
  padding-bottom: 80px;
}
</style>
